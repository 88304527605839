@import "../../base.scss";
.pcNone {
  display: none !important;
  @include mobile {
    display: block !important;
    & > span {
      font-family: Pretendard;
      color: #017534;
      font-size: 16px;
      font-weight: 700;
      text-align: center;
      width: 100%;
      padding: 8px;
      padding-top: 24px;
      display: block;
      box-sizing: border-box;
    }
  }
}
.mobileNone {
  @include mobile {
    display: none !important;
  }
}
.wrapper {
  display: flex;
  @include mobile {
    max-width: 100vw;
    overflow-x: hidden;
    position: relative;
  }
  a {
    text-decoration: none;
  }
}
.sideBarWrapper {
  width: 320px;
  min-height: 100svh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 32px 0;
  z-index: 1;
  gap: 44px;
  background: #fff;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.1);
  .logo {
    width: 240px;
    height: 63.922px;
    @include background {
      background-image: url("../../../assets/images/logo.png");
      background-size: auto 100%;
    }
    margin: 0 auto;
  }
  .user-info-card {
    display: flex;
    padding: var(--0, 0px) var(--6, 24px);
    flex-direction: column;
    align-items: center;
    gap: var(--3, 16px);
    width: 100%;
    box-sizing: border-box;
    .role {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 4px;

      span {
        color: #017534;
        font-size: 16px;
        font-weight: 700;
      }
      .icon {
        width: var(--6, 24px);
        height: var(--6, 24px);
        display: block;
      }
    }

    .user-details {
      display: flex;
      padding: var(--3, 12px) var(--3, 12px) var(--4, 12px) var(--3, 12px);
      flex-direction: column;
      align-items: flex-start;
      gap: var(--3, 12px);
      border-radius: 8px;
      background: #f9f9f9;
      width: 100%;
      font-family: Pretendard;

      .user-name {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        span {
          color: #373737;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
        }
        .icon {
          width: var(--6, 16px);
          height: var(--6, 16px);
          display: block;
        }
      }

      .contact-info {
        color: #2c2c2c;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }

  .sideBarElemTwoDepth {
    height: 0px;
    overflow: hidden;
    transition: height 0.4s;

    transition-timing-function: ease-in-out;
    background-color: #fafafa;
    &.active {
      &.count4 {
        height: calc(56px * 4);
      }
      &.count5 {
        height: calc(56px * 5);
      }
      &.count3 {
        height: calc(56px * 3);
      }
      &.count2 {
        height: calc(56px * 2);
      }
      &.count1 {
        height: calc(56px * 1);
      }
    }
  }
  .sideBarElemTwoDepth .sideBarElem {
    span {
      color: #aeaeae;
      font-weight: 600;
    }
    .menuIcon {
      filter: brightness(0) saturate(100%) invert(76%) sepia(1%) saturate(352%)
        hue-rotate(314deg) brightness(92%) contrast(91%);
    }
  }
  .sideBarElem {
    display: flex;
    padding: var(--4, 16px) var(--6, 24px);
    align-items: center;
    gap: 8px;
    font-family: Pretendard;
    transition: all 0.4s;

    &.active {
      background-color: #017534;
      span {
        color: #fff;
      }
      .menuIcon,
      .sideBarMenuArrow {
        filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%)
          hue-rotate(93deg) brightness(103%) contrast(103%);
      }
    }
    &:hover {
      background: #f4ffee;
      span {
        color: #017534;
      }
      .menuIcon,
      .sideBarMenuArrow {
        filter: brightness(0) saturate(100%) invert(13%) sepia(100%)
          saturate(7499%) hue-rotate(161deg) brightness(96%) contrast(101%);
      }
    }

    span {
      color: #4e4e4e;
      font-size: 16px;
      font-weight: 600;
    }
    .sideBarMenuIcon {
      position: relative;
      width: 18px;
      height: 18px;
      margin-right: 12px;
      background-position: center;
      background-size: 90% auto;
      background-repeat: no-repeat;
      filter: brightness(0) saturate(100%) invert(30%) sepia(0%) saturate(2404%)
        hue-rotate(241deg) brightness(98%) contrast(83%);
    }
    .sideBarMenuArrow {
      margin-left: auto;
      width: 24px;
      height: 24px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='17' viewBox='0 0 16 17' fill='none'%3E%3Cpath d='M12 6.92188L8 10.9219L4 6.92188' stroke='%23333333'/%3E%3C/svg%3E");
    }
  }
  hr {
    margin-top: auto;
    background-color: #ccc;
    height: 2px;
    width: 100%;
    outline: none;
    border: none;
    margin-bottom: 40px;
  }
  .mobile {
    display: none;
  }
  @include mobile {
    position: absolute;
    width: 100svw;
    right: -100%;
    top: 0;
    z-index: 100;
    background-color: white;
    height: 100svh;
    padding: 0;
    padding-top: 24px;

    padding-bottom: 20px;
    opacity: 0;
    pointer-events: none;
    transition: all 0.6s, opacity 0.3s;
    &.closed {
      right: -100%;
      opacity: 0;
      pointer-events: none;
    }
    &.mobileActive {
      opacity: 1;
      pointer-events: all;
      right: 0;
    }
    .sideBarElemTwoDepth {
      margin: 0 20px;
      .sideBarElem {
        margin: 0;
      }
      &.active {
        height: calc(37.2px * 3);
      }
    }
    .mobile {
      display: flex;
      height: fit-content;
      .contentWrapper {
        min-height: 0 !important;
        .contentDiv {
          display: none;
        }
      }
      .header {
        display: flex;
        flex-direction: column-reverse;
        align-items: start;
        justify-content: left;
        padding: 20px;
        margin-bottom: 24px;
        & > span {
          font-size: 18px;
          display: block;
          margin-top: 16px;
        }
        .buttonWrapper {
          margin-left: 0;
          width: 100%;
          .mobileClose {
            display: block;
            font-size: 24px;
            color: #aaa;
            transition: color 0.3s;
            &:hover {
              color: #555;
            }
          }
        }
        button {
          width: 40px;
          height: 40px;
          border-radius: 16px;
          background-size: 45%;
          &.alert {
            margin-right: 16px;
            background-size: 40%;
          }
        }
      }
      .mobileHeader {
        display: none;
      }
    }
    .sideBarLogoWrapper {
      display: none;
    }
    .sideBarElem {
      font-size: 16px;
      padding: 9px 12px;
    }
    hr {
      width: 85%;
    }

    .sideBarElem {
      margin: 0 20px;
    }
  }
}
.contentWrapper {
  flex: 1;
  width: 100%;
  background: #fafafa;
  box-shadow: 4px 0px 8px 0px rgba(0, 0, 0, 0.05) inset;
  @include mobile {
    min-height: 100svh;
  }
}
.headerWrapper {
  width: 100%;
  display: flex;
  padding: 18px var(--6, 24px);
  justify-content: space-between;
  align-items: center;
  background-color: white;
  font-family: Pretendard;
  border-bottom: 1px solid #e4e4e4;
  box-sizing: border-box;
  span {
    color: #000;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .notification-area {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: var(--8, 12px);
    position: relative;
    .notification-btn {
      display: flex;
      padding: 8px var(--4, 16px);
      align-items: center;
      gap: 8px;
      border-radius: 32px;
      background: #f3f3f3;
      border: none;
      .notification-icon {
        width: 18px;
        height: 18px;
        display: block;
      }
    }

    .user-info {
      display: flex;
      align-items: center;
      .user-name {
        color: #333;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
    & > .alertList {
      position: absolute;
      right: 0;
      top: 120%;
      transition: opacity 0.3s;
      opacity: 0;
      width: fit-content;
      pointer-events: none;
      z-index: 10;
      &.active {
        opacity: 1;
        pointer-events: all;
      }
      .alert-section {
        display: flex;
        gap: var(--15, 12px);
        flex-direction: column;
        width: fit-content;
        font-family: Pretendard;

        .alertListWrapper {
          display: flex;
          padding: var(--6, 12px);
          flex-direction: column;
          align-items: flex-start;
          flex: 1 0 0;
          align-self: stretch;
          border-radius: 8px;
          border: 1px solid #e4e4e4;
          background: #fff;
        }
        .alert-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: var(--15, 4px);
          padding: var(--3, 8px) var(--0, 0px);
          border-bottom: 1px solid #ececec;
          width: 100%;
          &:first-child {
            padding-top: 0;
          }
          &:last-child {
            border-bottom: none;
            padding-bottom: 0;
          }
          .titleWrapper {
            display: flex;
            align-items: center;
            gap: var(--15, 6px);
            color: #23232a;
            font-weight: 500;
            white-space: nowrap;
            width: 100%;
            span {
              font-size: 14px;
              &:first-child {
                flex: 0.4;
              }
              &:last-child {
                flex: 1;
                text-overflow: ellipsis;
                width: 100%;
                overflow: hidden;
              }
            }
            @include mobile {
              span {
                font-size: 12px;
              }
            }
          }
          .alert-date {
            color: #7d7d7d;
            font-size: 12px;
            font-weight: 400;
            white-space: nowrap;
          }
        }
      }
    }
  }
  @include mobile {
    padding: 18px 20px;
    & > span {
      font-size: 18px;
      display: none;
    }
    .notification-area {
      & > .alertList {
        left: 0;
        right: initial;
      }
      .user-info {
        .user-name {
          font-size: 14px;
        }
      }
      .notification-btn {
        padding: 8px 12px;
      }
    }
  }
}
