@import "../../base.scss";
.header {
  display: flex;
  width: 100%;
  padding: 8px 12svw;
  box-sizing: border-box;
  align-items: center;
  position: fixed;
  background-color: #fff;
  z-index: 100;
  gap: 64px;
  border-bottom: 1px solid var(--gray-200, #e5e7eb);
  .ham {
    display: none;
    width: 24px;
    height: 24px;
    position: absolute;
    right: 16px;
    top: 24px;
    z-index: 10;
    @include background {
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 7H19' stroke='%2333363F' stroke-width='2' stroke-linecap='round'/%3E%3Cpath d='M5 12H19' stroke='%2333363F' stroke-width='2' stroke-linecap='round'/%3E%3Cpath d='M5 17H19' stroke='%2333363F' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A");
      background-size: 100% 100%;
    }
  }
  .logo {
    width: 160px;
    height: 43.922px;
    @include background {
      background-image: url("../../../assets/images/logo.png");
      background-size: auto 100%;
    }
  }

  ul {
    gap: 0;
    display: flex;
    font-family: Pretendard;
    flex: 1;
    & > a {
      color: var(--gray-500, var(--gray-500, #3e4045));
      text-align: center;
      font-size: 18px;
      font-weight: 700;
      margin-left: auto;
    }

    li {
      cursor: pointer;
      width: 176px;
      color: #6b7280;
      text-align: center;
      font-size: 18px;
      font-weight: 500;
      position: relative;
      &:hover {
        color: #2e2e2e;
        font-weight: 600;
      }
      div {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        left: 0;
        top: calc(100% + 24px);
        gap: 18px;
        padding: 16px 12px;
        box-sizing: border-box;
        border-radius: 6px;
        border: 1px solid var(--gray-200, #e5e7eb);
        background: var(--white, #fff);
        width: 100%;
        min-width: fit-content;

        opacity: 0;
        pointer-events: none;
        transition: opacity 0.3s;
        &.active {
          opacity: 1;
          pointer-events: all;
        }
        span {
          color: #2e2e2e;
          font-size: 18px;
          font-weight: 700;
        }
        ul {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 14px;
          li {
            color: #6b7280;
            font-size: 16px;
            font-weight: 500;
          }
        }
      }
      @include desktop() {
        font-size: 18px;
      }
    }
  }
  @include mobile() {
    padding: 12px 16px;
    flex-direction: column;
    gap: 24px;
    .ham {
      display: block;
    }
    ul {
      display: none;
      gap: 24px;
      flex-direction: column;
      &.active {
        display: flex;
      }
      & > a {
        font-size: 16px;
        margin: 0;
        margin-bottom: 24px;
      }
      li {
        font-size: 16px;
        font-weight: 600;
        div {
          border: none;
          position: relative;
          height: 0;
          padding: 0;
          display: none;
          span {
            font-size: 14px;
            color: #5e6064;
          }
          li a {
            font-size: 14px;
          }
          &.active {
            display: flex;
            height: auto;
            padding: 16px 0;
          }
        }
      }
    }
  }
}
.footer-wrapper {
  font-family: Pretendard !important;

  border-top: 1px solid #ddd;
  margin-top: 160px;
  .logo {
    width: 148px;
    height: 43.922px;
    @include background {
      background-image: url("../../../assets/images/logo.png");
      background-size: auto 100%;
    }
  }

  .footer-links {
    padding: 20px 12svw;
    border-bottom: 1px solid #e9ebf4;

    display: flex;
    justify-content: space-between;

    a {
      color: #727584;
      font-size: 16px;
      font-weight: 400;

      b {
        color: #505360;
        font-weight: 600;
      }
    }
  }
  hr {
    height: 0;
    width: 76svw;
    box-sizing: border-box;
    border: none;
    border-top: 1px solid #e9ebf4;
  }
  .customer-center {
    padding: 28px 12svw;
    display: flex;
    justify-content: space-between;

    .contact-info {
      display: flex;
      flex-direction: column;
      gap: 24px;
      h2 {
        color: #505360;
        font-size: 18px;
        font-weight: 400;
      }
      p.phone-number {
        display: flex;
        gap: 8px;
        align-items: flex-end;
        font-size: 32px;
        font-weight: 800;
        color: #1d2c6f;
        filter: brightness(0) saturate(100%) invert(16%) sepia(48%)
          saturate(2309%) hue-rotate(209deg) brightness(91%) contrast(95%);
        span {
          width: 28px;
          height: 28px;
        }
      }
      .info-wrapper {
        display: flex;
        flex-direction: column;
        gap: 8px;

        p {
          color: #7b7d8b;
          font-size: 16px;
          font-weight: 400;
          b {
            font-weight: 600;
          }
        }
      }
    }
    .company-details {
      display: flex;
      flex-direction: column;
      gap: 8px;

      p {
        color: #9497a6;
        font-size: 14px;
        font-weight: 400;
        display: flex;
        gap: 8px;
        b {
          font-weight: 700;
          color: #9497a6;

          &.green {
            color: #017534;
          }
        }
        &.hide {
          color: #b3b6c4;
        }
      }
    }
  }
  @include mobile() {
    margin-top: 60px;
    padding-bottom: 62px;
    .footer-links {
      padding: 16px 16px;

      a {
        font-size: 10px;
        font-weight: 600;

        b {
          font-weight: 800;
        }
      }
    }
    hr {
      width: calc(100svw - 32px);
    }
    .customer-center {
      padding: 16px;
      .contact-info {
        gap: 16px;
        h2 {
          font-size: 12px;
        }
        p.phone-number {
          font-size: 18px;
          span {
            width: 16px;
            height: 16px;
          }
        }
        .info-wrapper {
          gap: 6px;

          p {
            font-size: 12px;
            font-weight: 500;
            b {
              font-weight: 700;
            }
          }
        }
        &:last-child {
          display: none;
        }
      }
      .company-details {
        gap: 8px;
        p {
          font-size: 12px;
          font-weight: 500;
          gap: 6px;
          flex-wrap: wrap;
          b {
            font-weight: 700;
          }
        }
      }
    }
  }
}
