@mixin pageSetting {
  @content;
  &.dontWrapPage {
    padding: 28px 12svw;
    @include mobile() {
      padding: 24px 6svw;
    }
  }
  &.blendBackground {
    background: rgba(22, 22, 22, 0.329);
    backdrop-filter: blur(8px);
  }
}
@mixin mobile {
  @media screen and (max-width: 600px) {
    @content;
  }
}
@mixin desktop {
  @media screen and (max-width: 1200px) {
    @content;
  }
}
@mixin iconBackgroundSetting {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% auto;
  width: 24px;
  height: 24px;
  display: flex;
  @content;

  &.white {
    filter: brightness(0) saturate(100%) invert(100%) sepia(99%) saturate(0%)
      hue-rotate(51deg) brightness(105%) contrast(100%) !important;
  }
  &.green {
    filter: brightness(0) saturate(100%) invert(35%) sepia(25%) saturate(1760%)
      hue-rotate(100deg) brightness(92%) contrast(106%);
  }
  &.black {
    filter: brightness(0) saturate(100%) invert(8%) sepia(16%) saturate(935%)
      hue-rotate(125deg) brightness(90%) contrast(88%);
  }
  &.gray {
    filter: brightness(0) saturate(100%) invert(84%) sepia(5%) saturate(511%)
      hue-rotate(135deg) brightness(82%) contrast(83%);
  }
}

@keyframes leftToRight {
  0% {
    transform: translate3d(0svw, 0, 0);
  }
  50% {
    transform: translate3d(-100svw, 0, 0);
  }
  50.1% {
    transform: translate3d(-100svw, 0, 0);
  }
  100% {
    transform: translate3d(0svw, 0, 0);
  }
}
@keyframes rightToLeft {
  0% {
    transform: translate3d(-100svw, 0, 0);
  }
  50% {
    transform: translate3d(0svw, 0, 0);
  }
  50.1% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-100svw, 0, 0);
  }
}
