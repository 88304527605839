@import "../../base.scss";
.logo {
  width: 240px;
  height: 63.922px;
  @include background {
    background-image: url("../../../assets/images/logo.png");
    background-size: auto 100%;
  }
  margin: 0 auto;
}
.wrapper {
  padding-top: 60.92px;
  @include mobile() {
    padding-top: 68.92px;
  }
}
.sliderContainer {
  width: 100%;
  height: 588px;
  overflow: hidden;
  & > div {
    & > ul {
      position: absolute;
      top: 64%;
      left: 12svw;
      width: fit-content;
      height: fit-content;
      li {
        width: fit-content;
        button::before {
          font-size: 12px;
          color: #017534 !important;
        }
      }
    }
  }
  @include mobile() {
    height: 248px;
    & > div {
      & > ul {
        top: 76%;
        left: 16px;
        li {
          button::before {
            font-size: 10px;

            color: #b0ff88 !important;
          }
        }
      }
    }
  }
}
.sliderWrapper {
  @include background() {
  }
  height: 588px;
  &.slider1 {
    background-image: url("../../../assets/images/slider1.png");
  }
  &.slider2 {
    background-image: url("../../../assets/images/slider2.png");
  }
  &.slider3 {
    background-image: url("../../../assets/images/slider3.png");
  }
  & > div {
    width: 100%;
    height: 100%;
    padding: 0 12svw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    font-family: Pretendard;
    gap: 18px;
    .titleWrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: var(--4, 12px);
      h3 {
        color: #2f2f2f;
        font-size: 24px;
        font-weight: 600;
      }
      h1 {
        color: #017534;
        font-size: 38px;
        font-style: normal;
        font-weight: 700;
      }
    }
  }

  &.content {
    height: 420px;
    &.slider5 {
      background: url("../../../assets/images/slider5.png");
      background-position: center 30%;
      background-size: 100% auto;
    }
    &.slider6 {
      background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.5) 0%,
          rgba(0, 0, 0, 0.5) 100%
        ),
        url("../../../assets/images/slider6.png");
      background-position: center 30%;
      background-size: 100% auto;
    }
    &.slider7 {
      background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.5) 0%,
          rgba(0, 0, 0, 0.5) 100%
        ),
        url("../../../assets/images/slider7.png");
      background-position: center 30%;
      background-size: 100% auto;
    }

    div {
      align-items: center;
      font-family: Pretendard;
      box-sizing: border-box;
      div {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 18px;
        h1 {
          color: #fff;
          font-size: 24px;
          font-weight: 600;
        }
        h3 {
          color: #fff;
          font-size: 16px;
          font-weight: 400;
        }
      }
    }
  }
  @include mobile() {
    height: 208px;
    background-size: 200% auto !important;
    &.content {
      height: 208px;
    }
    &.slider1 {
      background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.5) 0%,
          rgba(0, 0, 0, 0.5) 100%
        ),
        url("../../../assets/images/slider1.png");
      background-position: center 30%;
    }
    &.slider2 {
      background-image: url("../../../assets/images/slider2.png");
      background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.5) 0%,
          rgba(0, 0, 0, 0.5) 100%
        ),
        url("../../../assets/images/slider2.png");
      background-position: center;
    }
    &.slider3 {
      background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.5) 0%,
          rgba(0, 0, 0, 0.5) 100%
        ),
        url("../../../assets/images/slider3.png");
      background-position: 70% center;
    }
    & > div {
      padding: 0 16px;
      gap: 12px;
      .titleWrapper {
        gap: 8px;
        h3 {
          font-size: 16px;
          color: #fff;
        }
        h1 {
          font-size: 20px;
          color: #fff;
        }
      }
    }
  }
}

.sectionWrapper {
  display: flex;
  padding: 92px 12svw;
  flex-direction: column;
  align-items: center;
  gap: var(--25, 48px);
  &.section2 {
    padding: 182px 12svw;
    background-image: url("../../../assets/images/slider4.png");
    .itemWrapper {
      width: 100%;
      display: flex;
      padding: var(--8, 32px) var(--6, 24px);
      flex-direction: column;
      align-items: center;
      gap: var(--1, 8px);
      flex: 1 0 0;
      font-family: Pretendard;
      background: #fff;
      text-align: center;
      border-radius: 8px;
      h1 {
        color: #252525;
        font-size: 16px;
        font-weight: 600;
        white-space: nowrap;
      }
      h2 {
        color: #017534;
        font-size: 24px;
        font-weight: 700;
      }
    }
    @include mobile() {
      padding: 92px 16px;
      gap: 24px;
      .itemWrapper {
        flex: 1;
        max-width: calc(50% - 12px);
        box-sizing: border-box;
        h1 {
          font-size: 14px;
        }
        h2 {
          font-size: 20px;
        }
      }
    }
  }
  &.gray {
    padding: 128px 12svw;
    background: #f3f3f3;
  }
  .compItemWrapper {
    cursor: pointer;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--1, 8px);
    flex: 1 0 0;
    font-family: Pretendard;
    background: #fff;
    text-align: center;
    border-radius: 8px;
    img {
      width: 86px;
      height: 86px;
      object-fit: scale-down;
    }
    h1 {
      color: #616161;
      text-align: center;
      font-size: 16px;
      font-weight: 600;
      white-space: nowrap;
    }
    h2 {
      color: #017534;
      font-size: 24px;
      font-weight: 700;
      white-space: nowrap;
    }
  }
  @include mobile() {
    gap: 24px;
    padding: 32px 16px;
    &.gray {
      padding: 32px 16px;
      background: #fcfcfc;
    }

    .compItemWrapper {
      flex: 1;
      min-width: 28%;
      max-width: calc(50% - 12px);
      box-sizing: border-box;
      h1 {
        font-size: 14px;
      }
      h2 {
        font-size: 20px;
      }
      &:last-child {
        max-width: 100%;
      }
    }
  }
}
.sectionFirstContentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--4, 16px);
  font-family: Pretendard;
  width: 100%;

  h1 {
    color: #017534;
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    line-height: 100%; /* 32px */
  }
  @include mobile() {
    h1 {
      font-size: 18px;
    }
  }
}
.sectionTwoContentWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--6, 24px);
  font-family: Pretendard;
  &.year {
    gap: 0;
  }
  &.row {
    flex-direction: row;
    align-items: stretch;
  }
  .imgRowWrapper {
    display: flex;
    align-items: flex-start;
    gap: var(--6, 24px);
    height: 240px;
    width: 100%;
    img {
      border: 1px solid #f2f2f2;
      border-radius: 8px;
      flex: 1;
      height: 240px;
      width: calc(50% - 12px);
      object-fit: cover;
    }
  }
  p.contentWrapper {
    width: 100%;
    color: #2b2b2b;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    padding: 0;
  }
  div.contentInfoWrapper {
    width: 100%;
    display: flex;
    padding: var(--6, 24px);
    flex-direction: column;
    gap: var(--4, 16px);
    border-radius: var(--rounded-lg, 8px);
    background: var(--white, #fff);
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
    &.green {
      background: #f8fff2;
    }
    h3 {
      color: #017534;
      font-size: 18px;
      font-weight: 700;
      line-height: 125%; /* 22.5px */
      display: flex;
      align-items: center;
      gap: 8px;
      &.gray {
        color: #414141;
      }
      span {
        display: block;
        width: 22px;
        height: 22px;
      }
    }
    p {
      color: var(--gray-900, #111928);
      font-size: 16px;
      font-weight: 400;
      line-height: 120%;
      &.space {
        line-height: 150%;
      }
      b {
        font-weight: 800;
        &.green {
          color: #017534;
        }
      }
    }
    &.year {
      flex-direction: row;
      align-items: flex-start;
      box-shadow: none;
      border-radius: 0;
      border-bottom: 1px solid #cfcfcf;
      h3 {
        width: 120px;
        min-width: fit-content;
      }
      p {
        flex: 1;
        color: #2b2b2b;
        font-size: 16px;
        font-style: normal;
        line-height: 20px;
        display: flex;
        flex-direction: column;
        gap: 12px;
        b {
          display: block;
          font-weight: 800;
          &.green {
            color: #017534;
          }
          &.row {
            img {
              width: 154px;
              height: 67px;
            }
            display: flex;
            align-items: center;
          }
        }
      }
      @include mobile() {
        flex-direction: column;
        p {
          gap: 6px;
          font-size: 14px;
        }
      }
    }
  }
  @include mobile() {
    gap: 18px;
    .imgRowWrapper {
      gap: 8px;
      height: 160px;
      img {
        height: 160px;
      }
    }
    p.contentWrapper {
      font-size: 14px;
    }
    &.row.mobileCol {
      flex-direction: column;
    }
    div.contentInfoWrapper {
      padding: 16px;
      gap: 8px;
      box-sizing: border-box;
      h3 {
        font-size: 16px;
        gap: 6px;
        span {
          width: 16px;
          height: 16px;
        }
      }
      p {
        font-size: 12px;
        font-weight: 500;
      }
    }
  }
}
.titleWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--3, 12px);
  font-family: Pretendard;
  h2 {
    color: var(--gray-900, var(--gray-900, #111928));
    font-size: 32px;
    font-weight: 700;
  }
  p {
    color: #303237;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
  }
  @include mobile() {
    h2 {
      font-size: 20px;
    }
    p {
      font-size: 14px;
    }
  }
}
// atoms

.noticeListWrapper {
  display: flex;
  align-items: stretch;
  gap: 24px;
  width: 100%;

  &.center {
    justify-content: center;
  }
  &.row {
    flex-direction: row;
    flex-wrap: wrap;
  }
  @include mobile() {
    flex-direction: column;
    gap: 24px;
    width: 100%;
    flex-wrap: wrap;
    &.center {
      align-items: center;
    }
    &.row {
    }
  }
}
.noticeWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--5, 16px);
  font-family: Pretendard;
  flex: 1;
  min-width: calc(25% - 24px);
  .noticeTitleWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--5, 12px);
    width: 100%;
    flex: 1;
    img {
      width: 100%;
      object-fit: cover;
      max-height: 440px;
      border-radius: 8px;
      flex: 1;
    }
    h3 {
      color: var(--gray-900, var(--gray-900, #111928));
      font-size: 20px;
      font-weight: 700;
      min-height: 60px;
    }
  }
  p {
    color: var(--gray-500, var(--gray-500, #6b7280));
    font-size: 16px;
    font-weight: 400;
  }
  .viewButton {
    color: #5a5a5a;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    gap: 4px;
    align-items: center;
    span {
      @include background() {
        width: 18px;
        height: 18px;

        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12' fill='none'%3E%3Cpath d='M11.9348 6.34337C11.9996 6.17929 12.0166 5.99875 11.9835 5.82457C11.9504 5.65039 11.8687 5.49041 11.7487 5.36487L8.31995 1.77406C8.24088 1.68832 8.14629 1.61993 8.04171 1.57288C7.93713 1.52583 7.82465 1.50107 7.71083 1.50003C7.59701 1.499 7.48414 1.52171 7.37879 1.56685C7.27344 1.61199 7.17774 1.67864 7.09725 1.76293C7.01677 1.84722 6.95312 1.94745 6.91002 2.05777C6.86692 2.1681 6.84523 2.28631 6.84622 2.4055C6.84721 2.5247 6.87085 2.6425 6.91578 2.75202C6.9607 2.86154 7.02601 2.9606 7.10788 3.04341L9.07429 5.10274H0.857197C0.629854 5.10274 0.411823 5.19732 0.251067 5.36568C0.0903117 5.53403 0 5.76236 0 6.00045C0 6.23853 0.0903117 6.46687 0.251067 6.63522C0.411823 6.80357 0.629854 6.89815 0.857197 6.89815H9.07429L7.10874 8.95659C7.02686 9.0394 6.96156 9.13846 6.91664 9.24798C6.87171 9.3575 6.84806 9.4753 6.84707 9.5945C6.84609 9.71369 6.86777 9.8319 6.91088 9.94223C6.95398 10.0526 7.01763 10.1528 7.09811 10.2371C7.1786 10.3214 7.2743 10.388 7.37965 10.4332C7.485 10.4783 7.59787 10.501 7.71169 10.5C7.82551 10.4989 7.93799 10.4742 8.04257 10.4271C8.14715 10.3801 8.24174 10.3117 8.32081 10.2259L11.7496 6.63513C11.829 6.55155 11.8919 6.45241 11.9348 6.34337Z' fill='%235A5A5A'/%3E%3C/svg%3E");
      }
    }
  }
  @include mobile() {
    gap: 12px;
    min-width: 100%;
    .noticeTitleWrapper {
      h3 {
        font-size: 16px;
      }
    }
    p {
      font-size: 14px;
    }
    .viewButton {
      font-size: 14px;
    }
  }
}
.serviceWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: Pretendard;
  gap: 18px;
  h1 {
    color: var(--gray-900, #111928);
    font-size: 18px;
    font-weight: 600;
    white-space: nowrap;
  }
  ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--3, 12px);
    width: 100%;
    li {
      color: #3e4248;
      font-size: 16px;
      font-weight: 500;
      white-space: nowrap;
      a {
        color: #3e4248;
        font-size: 16px;
        font-weight: 500;
        white-space: nowrap;
        text-decoration: none;
      }
    }
  }
  @include mobile() {
    max-width: 100%;
    padding: 16px;
    border-radius: var(--rounded-lg, 8px);
    border: 1px solid #ededed;
    background: var(--white, #fff);
    width: 100%;
    box-sizing: border-box;
    h1 {
      font-size: 16px;
    }
    ul {
      li {
        font-size: 14px;
      }
    }
  }
}
.infoBoxWrapper {
  width: 100%;
  box-sizing: border-box;
  padding: 24px 32px;
  justify-content: center;
  align-items: center;
  gap: var(--25, 12px);
  color: #3a3a3a;
  text-align: center;
  font-family: Pretendard;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 33px */
  background: #fbfbfb;
  border-radius: 8px;
  b {
    font-weight: 800;
    &.green {
      color: #017534;
    }
  }
  @include mobile() {
    padding: 16px;
    gap: 12px;
    font-size: 14px;
  }
}
.personItemWrapper {
  display: flex;
  padding: var(--0, 0px) var(--3, 12px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--4, 16px);
  flex: 1;
  font-family: Pretendard;
  box-sizing: border-box;
  img {
    width: 144px;
    height: 144px;
    border-radius: 320px;
  }
  span.title {
    color: var(--gray-500, var(--gray-500, #6b7280));
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 150%; /* 24px */
  }
  span.name {
    color: #017534;
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    line-height: 125%; /* 30px */
  }
  span.info {
    color: var(--gray-500, var(--gray-500, #6b7280));
    text-align: center;
    font-size: 16px;
    font-weight: 500;
  }
  @include mobile() {
    gap: 6px;
    img {
      width: 96px;
      height: 96px;
    }
    span.title {
      font-size: 14px;
    }
    span.name {
      font-size: 20px;
    }
    span.info {
      font-size: 14px;
    }
  }
}
.page-nav {
  margin: 36px 0;
  display: flex;
  gap: 12px;
  justify-content: center;
  border-radius: 4px;
  button {
    transition: background-color 0.2s, color 0.2s;
    border: none;
    background-color: #fff;
    width: 36px;
    height: 36px;
    color: #92bb8b;
    font-size: 14px;
    font-weight: 400;
    border-radius: 6px;
    .leftThinArrowIcon,
    .rightThinArrowIcon {
      width: 20px;
      height: 20px;
      filter: brightness(0) saturate(100%) invert(69%) sepia(9%) saturate(940%)
        hue-rotate(180deg) brightness(88%) contrast(84%);
    }
    &.active,
    &:hover {
      color: #017534;
      font-weight: 500;
      background-color: #e5ffdb;
      .leftThinArrowIcon,
      .rightThinArrowIcon {
        filter: brightness(0) saturate(100%) invert(99%) sepia(100%)
          saturate(0%) hue-rotate(192deg) brightness(108%) contrast(100%);
      }
    }
  }
  @include mobile() {
    button {
      width: 28px;
      height: 28px;
      font-size: 12px;
    }
  }
}
.joinSection {
  font-family: Pretendard;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--3, 12px);
  h2 {
    color: #252525;
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    width: 100%;
  }
}
.tableWrapper {
  font-family: Pretendard;
  span {
    display: block;
    padding: 8px 0;
    color: #2b2b2b;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%; /* 24px */
    width: 100%;
    text-align: right;
  }
  width: 100%;
  .dataTable {
    width: 100%;

    thead {
      border-bottom: 1px solid #ebffe2;
      background: #f6fff1;

      th {
        padding: var(--6, 8px);

        color: #017534;
        font-size: 18px;
        font-weight: 700;
        line-height: 125%; /* 22.5px */
        text-align: left;
      }
    }
    tbody {
      tr {
        border-bottom: 1px solid #ebffe2;
        &:nth-child(2n) {
          background: #fdfffc;
        }
        td {
          padding: var(--6, 8px);
          color: #2b2b2b;
          font-size: 16px;
          font-weight: 400;
          line-height: 150%; /* 24px */
        }
      }
    }
  }
  @include mobile() {
    width: 100%;
    overflow: scroll;
    span {
      font-size: 12px;
      font-weight: 500;
    }
    .dataTable {
      thead {
        th {
          font-size: 12px;
        }
      }
      tbody {
        tr {
          td {
            font-size: 14px;
            white-space: nowrap;
          }
        }
      }
    }
  }
}
.iconImgWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--6, 24px);
  font-family: Pretendard;
  align-self: stretch;
  span {
    color: #838383;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
  }
}
.table-wrapper {
  border-radius: 8px;
  border: 1px solid #f2f4ff;
  display: table;
  table-layout: auto; /* 테이블 셀의 너비가 컨텐츠에 맞게 조정됨 */
  width: 100%;
  * th span {
    white-space: nowrap;
  }
  .item-wrapper {
    display: table-cell;
  }
  .table-row {
    display: table-row;

    .item {
      font-family: Pretendard;
      display: table-cell;
      padding: 12px 24px;
      color: #4b4c5b;
      font-size: 16px;
      font-weight: 500;
      line-height: 120%; /* 19.2px */
      align-items: center;
      vertical-align: middle;
      &.center {
        text-align: center;
      }
      &.left {
        text-align: left;
      }
      &.right {
        text-align: right;
      }

      .icon {
        display: inline-block;
        width: 16px;
        height: 16px;
      }
    }
    &.data {
      &:nth-child(2n) {
        background: #fdfdfd;
      }
      .item {
        img {
          width: 80px;
          height: 80px;
        }
        .gray {
          color: #6b7280;
          font-weight: 400;
        }
        .item-data {
          display: inline-flex;
          gap: 4px;
          align-items: flex-start;
          &.center {
            align-items: center;
          }
          b {
            margin-top: 2px;
            color: #111928;
            font-weight: 600;
            line-height: 140%;
          }
          input {
            color: #111928;
            font-size: 14px;
            font-weight: 500;
            line-height: 150%; /* 21px */
            width: 40px;
            border: 1px solid #d2d3da;
            padding: 2px 8px;
            border-radius: 4px;
            text-align: center;
          }
          button {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 24px;
            height: 24px;
            border-radius: 4px;
            border: 1px solid transparent;
            background: #017534;
            div {
              width: 12px;
              height: 12px;
            }
          }
        }
      }
    }
  }
  &.is-post {
    .table-row {
      .item {
        border-bottom: 1px solid #f2f4ff;
        &.title {
          background: #fdfdfd;
          color: #6b7280;
          font-weight: 500;
        }

        &.content {
          min-height: 240px;
          width: 100%;
          gap: 8px;
          img {
            width: 100%;
            height: auto;
            object-fit: cover;
          }
          h1 {
            color: #222;
            font-weight: 600;
            font-size: 16px;
            line-height: 150%;
          }
          p {
            color: #222;
            font-size: 16px;
            font-weight: 400;
            line-height: 140%;
          }
          table {
            width: 100%;
            border-collapse: collapse;
            th,
            td {
              border: 1px solid #ddd;
              padding: 8px;
              text-align: center;
              font-size: 16px;
              vertical-align: middle;
            }
            th {
              background-color: #f2f2f2;
            }
          }
        }
      }
      background-color: #fff;
      &:nth-child(2n) {
        background: #fff;
      }
      &:last-child .item {
        border-bottom: none;
      }
    }
  }
  @include mobile() {
    max-width: 100%;

    .table-row {
      max-width: 100%;

      .item {
        padding: 8px 12px;
        font-size: 12px;
        white-space: wrap;
        .icon {
          width: 14px;
          height: 14px;
        }
      }
      &.data {
        .item {
          img {
            width: 40px;
            height: 40px;
          }
          .item-data {
            display: inline-flex;
            gap: 4px;
            align-items: flex-start;
            &.center {
              align-items: center;
            }
            b {
              margin-top: 2px;
              color: #111928;
              font-weight: 600;
              line-height: 140%;
            }
            input {
              color: #111928;
              font-size: 14px;
              font-weight: 500;
              line-height: 150%; /* 21px */
              width: 40px;
              border: 1px solid #d2d3da;
              padding: 2px 8px;
              border-radius: 4px;
              text-align: center;
            }
            button {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 24px;
              height: 24px;
              border-radius: 4px;
              border: 1px solid transparent;
              background: #1a56db;
              div {
                width: 12px;
                height: 12px;
              }
            }
          }
        }
      }
      &.is-mobile {
        display: flex;
        flex-direction: column;
        padding: 14px;
        gap: 16px;
        .item {
          padding: 0;

          .item-data {
            padding: 0;
            &.is-total {
              font-size: 16px;
            }
          }
          &.img-wrapper {
            gap: 16px;
            display: flex;
            align-items: flex-start;
            img {
              width: 64px;
              height: 64px;
            }
          }
          &.product-name {
            width: 100%;
            box-sizing: border-box;
            .item-data {
              display: flex;
              flex-direction: column;
            }
          }
          &.price-wrapper {
            gap: 16px;
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            input {
              font-size: 12px;
            }
            button {
              width: 22px;
              height: 22px;
            }
            .item-data.price {
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              gap: 12px;
              span {
                font-size: 12px !important;
              }
              b {
                font-size: 17px !important;
              }
            }
          }
          &.close {
            padding: 8px 0;
            text-align: center;
          }
        }
      }
    }
    &.is-post {
      .table-row {
        .item {
          border-bottom: 1px solid #f2f4ff;
          &.title {
            background: #f9fcff;
            color: #6b7280;
            font-weight: 500;
          }
        }
        background-color: #fff;
        &:nth-child(2n) {
          background: #fff;
        }
        &:last-child .item {
          border-bottom: none;
        }
      }
    }
  }
}
.is-row-scroll {
  width: 100%;
}
.qna-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  border: 1px solid #f2f4ff;
  font-family: Pretendard;
  .title-wrapper {
    display: flex;
    gap: 8px;
    align-items: center;
    padding: 14px 18px;
    border-radius: 4px 4px 0px 0px;
  }
  .content-wrapper {
    padding: 18px 24px;
    color: #515151;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%; /* 24px */
    display: flex;
    gap: 8px;
    flex-direction: column;
    b {
      font-weight: 600;
    }
    .row-wrapper {
      display: flex;
      gap: 24px;
      margin: 32px 0;
      align-items: center;
      .inner-content {
        flex: 1;
        display: flex;
        padding: 20px 24px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 12px;
        h4 {
          font-size: 18px;
          font-weight: 700;
          line-height: 150%; /* 27px */
        }
        b {
          font-size: 16px;
          font-weight: 600;
          line-height: 100%; /* 16px */
        }
        &.blue {
          color: #1a56db;
          background: #f0f9ff;
        }
        &.red {
          color: #eb0000;
          background: #fff2f2;
        }
      }
      .step-item {
        flex: 1;
        display: flex;
        padding: 18px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 4px;
        .step {
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 150%; /* 21px */
        }
        .title {
          color: #32353b;
          font-size: 18px;
          font-weight: 600;
          line-height: 100%; /* 18px */
        }
        &.blue {
          background: #f0f9ff;
          .step {
            color: #1a56db;
          }
        }
        &.green {
          background: #f4fff0;

          .step {
            color: #45b500;
          }
        }
      }
    }
    .column-wrapper {
      margin-bottom: 24px;

      display: flex;
      gap: 16px;
      flex-direction: column;
      color: #32353b;
      font-size: 16px;
      font-weight: 400;
      line-height: 100%; /* 16px */
      width: 100%;
      li {
        display: flex;
        width: 100%;

        span {
          display: inline-block;

          &.bold {
            color: #474747;
            font-weight: 700;
            flex: 1;
            max-width: 20%;
          }
        }
      }
    }
  }
  &.blue {
    .title-wrapper {
      background: #1a56db;
      color: white;
      div {
        filter: brightness(0) saturate(100%) invert(98%) sepia(0%)
          saturate(7500%) hue-rotate(272deg) brightness(101%) contrast(104%);
      }
    }
  }
  &.gray {
    .title-wrapper {
      background: #fff;
      color: #7c7d8d;
      border-bottom: 1px solid #f2f4ff;
      div {
        filter: brightness(0) saturate(100%) invert(49%) sepia(5%)
          saturate(969%) hue-rotate(199deg) brightness(99%) contrast(88%);
      }
    }
  }
  &.dark-gray {
    .title-wrapper {
      background: #fff;
      border-bottom: 1px solid #f2f4ff;
      span {
        color: #017534;
        font-weight: 700;
      }
      div {
        filter: brightness(0) saturate(100%) invert(15%) sepia(10%)
          saturate(739%) hue-rotate(182deg) brightness(96%) contrast(82%);
      }
    }
  }
  @include mobile() {
    .title-wrapper {
      padding: 12px 14px;
      font-size: 14px;
      gap: 6px;
      div {
        width: 16px;
        height: 16px;
      }
    }
    .content-wrapper {
      padding: 16px 14px;
      font-size: 14px;
      .row-wrapper {
        gap: 12px;
        margin: 24px 0;
        align-items: center;
        &.is-bokji {
          flex-direction: column;
        }
        &.is_step {
          width: 100%;
          overflow-x: scroll;
        }
        .inner-content {
          padding: 16px 18px;
          gap: 8px;
          h4 {
            font-size: 16px;
          }
          b {
            font-size: 14px;
          }
        }
        .rightArrowIcon {
          min-width: 16px;
          max-width: 16px;
        }
        .step-item {
          padding: 12px 16px;
          .step {
            font-size: 12px;
          }
          .title {
            font-size: 14px;
            white-space: nowrap;
          }
        }
      }
      .column-wrapper {
        margin-bottom: 16px;
        gap: 12px;
        font-size: 14px;
        li {
          gap: 12px;

          span {
            display: inline-block;
            &.bold {
              min-width: 90px;
            }
          }
        }
      }
    }
  }
}
