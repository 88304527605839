@import "../../base.scss";
.flexRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  width: 100%;
  button {
    flex: 1;
  }
}
.contract-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--3, 12px);
  width: 100%;
  font-family: Pretendard;

  h2 {
    color: #151515;
    font-size: 20px;
    font-weight: 700;
    line-height: 125%; /* 25px */
  }
  .cardWrapper {
    display: flex;
    align-items: flex-start;
    gap: 18px;
    align-self: stretch;
    .contract-card {
      display: flex;
      padding: 18px;
      padding-top: 14px;
      flex-direction: column;
      align-items: flex-start;
      gap: var(--3, 8px);
      flex: 1 0 0;
      border-radius: 8px;
      border: 1px solid #dfdfdf;
      background: #fff;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
      flex: 1;
      span {
        color: #5a5d65;
        font-size: 16px;
        font-weight: 600;
        display: flex;
        align-items: center;
        gap: var(--05, 2px);
        .icon {
          width: var(--6, 24px);
          height: var(--6, 24px);
          display: block;
        }
      }
      .count {
        color: #303134;
        font-size: 20px;
        font-weight: 700;
        line-height: 24px; /* 100% */
      }
      &.complete {
        border: 1px solid #dfdfdf;
        background: #fff;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
        flex: 1;
        span {
          color: #017534;
        }
        .count {
          color: #017534;
        }
      }
      &.pending {
        border: 1px solid #dfdfdf;
        background: #fff;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
        flex: 1;
        span {
          color: #0053cf;
        }
        .count {
          color: #0053cf;
        }
      }
    }
    .contract-card.detail {
      font-family: Pretendard;
      gap: 12px;
      .stateWrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        h2 {
          display: flex;
          align-items: center;
          gap: 8px;
          .bedge {
            font-size: 14px;
          }
        }
        span {
          color: #747474;
          font-size: 14px;
          font-weight: 500;
        }
      }
      .alertWrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: var(--3, 8px);
        align-self: stretch;
        .alertTextWrapper {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          align-self: stretch;
          gap: 16px;
          &.red span {
            color: #c20000;
          }
          &.green span {
            color: #017534;
          }
          span {
            color: #2c2c2c;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 125%; /* 22.5px */
            &:last-child {
              font-weight: 700;
            }
          }
          & > .button {
            padding: 10px 12px;
          }
        }
        &.companyInfo {
          gap: 16px;
          .alertTextWrapper {
            & > span {
              font-weight: 500;
              &:first-child {
                font-weight: 400;
                width: 220px;
              }
              &:last-child {
                font-weight: 600;
                flex: 1;
              }
            }
            .changeButtonInputWrapper {
              display: flex;
              align-items: center;
              gap: 8px;
              & > span {
                font-weight: 600;
              }
              & > button {
                width: 120px;
                align-self: stretch;
              }

              & > input {
                flex: 1;
              }
              flex: 1;
            }
            input {
              align-self: stretch;
              font-family: Pretendard;
              padding: 6px 12px;
              align-items: center;
              gap: var(--15, 6px);
              border-radius: 4px;
              border: 1px solid #d2d2d9;
              color: #303033;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 150%; /* 21px */
            }
          }
        }
      }
      .button {
        width: 100%;
      }
    }
    .contract-card.detail.company {
      max-width: 840px;
    }
  }
  @include mobile {
    gap: 8px;
    h2 {
      font-size: 16px;
    }
    .cardWrapper {
      gap: 12px;
      flex-direction: column;
      .contract-card {
        padding: 12px;
        gap: 8px;
        width: 100%;
        box-sizing: border-box;
        span {
          font-size: 14px;
          span.icon {
            width: 18px;
            height: 18px;
          }
        }
        .count {
          font-size: 16px;
        }
      }
      .contract-card.detail {
        gap: 8px;
        .changeButtonInputWrapper {
          width: 100%;
          flex-direction: column;
          button {
            width: 100% !important;
          }
          input {
            box-sizing: border-box;
          }
        }
        .stateWrapper {
          flex-direction: column;
          align-items: flex-start;
          gap: 4px;
          h2 {
            font-size: 16px;
          }
          span {
            font-size: 12px;
          }
        }
        .alertWrapper {
          gap: 8px;
          .alertTextWrapper {
            gap: 8px;
            flex-direction: column;
            input {
              width: 100%;
            }
            span {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

.dashboard {
  display: flex;
  padding: var(--8, 32px);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--6, 24px);
  flex: 1 0 0;
  .row {
    display: flex;
    align-items: center;
    gap: 18px;
    width: 100%;
  }

  .misc-card {
    display: flex;
    padding: 18px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: var(--3, 12px);
    border-radius: 8px;
    border: 1px solid #e3e3e3;
    background: #fff;
    flex: 1;
    p {
      color: #33363f;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 125%; /* 22.5px */
    }

    div {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      width: 100%;
      span {
        color: #5a5d65;
        font-size: 16px;
        font-weight: 600;
        .count {
          font-weight: bold;
        }
      }
      &.complete {
        span {
          color: #017534;
        }
      }
    }
  }

  .alert-section {
    display: flex;
    gap: var(--15, 12px);
    flex-direction: column;
    width: 100%;
    font-family: Pretendard;
    h2 {
      color: #2b2b2b;
      font-size: 20px;
      font-weight: 700;
    }
    .alertListWrapper {
      display: flex;
      padding: var(--6, 18px);
      flex-direction: column;
      align-items: flex-start;
      flex: 1 0 0;
      align-self: stretch;
      border-radius: 12px;
      border: 1px solid #e4e4e4;
      background: #fff;
    }
    .alert-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: var(--15, 6px);
      padding: var(--3, 10px) var(--0, 0px);
      border-bottom: 1px solid #ececec;
      width: 100%;
      &:first-child {
        padding-top: 0;
      }
      &:last-child {
        border-bottom: none;
        padding-bottom: 0;
      }

      .titleWrapper {
        display: flex;
        align-items: center;
        gap: var(--15, 6px);
        color: #23232a;
        font-size: 16px;
        font-weight: 500;
        line-height: 150%; /* 24px */
        width: 100%;
        span:first-child {
          min-width: 108px;
          max-width: 108px;
        }
        span:last-child {
          flex: 1;
          text-overflow: ellipsis;
          width: 100%;
          overflow: hidden;
        }
        @include mobile {
          font-size: 14px;
          white-space: nowrap;
        }
      }
      .alert-date {
        color: #7d7d7d;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
  @include mobile {
    padding: var(--8, 16px);
    gap: 16px;
    .row {
      flex-direction: column;
      gap: 12px;
    }
    .misc-card {
      width: 100%;
      box-sizing: border-box;
      p {
        font-size: 16px;
      }
    }
    .alert-section {
      h2 {
        font-size: 16px;
      }
      .alertListWrapper {
        padding: 12px;
      }
    }
  }
}

.button {
  border-radius: 4px;
  background: #017534;
  padding: 6px 12px;
  &.shadow {
    box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.35);
  }
}
.productSearch {
  display: flex;
  padding: var(--8, 32px);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--6, 24px);
  flex: 1 0 0;
  font-family: Pretendard;
  .pageNavWrapper {
    margin: 0 auto;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    h1 {
      display: flex;
      align-items: center;
      gap: var(--15, 6px);
      color: #151515;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 125%; /* 25px */
    }
  }

  .filter-section {
    display: flex;
    padding: 18px;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--4, 16px);
    align-self: stretch;
    border-radius: 8px;
    font-family: Pretendard;
    background: #fff;
    border-bottom: 1px solid #e5e5e5;
    .filters {
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;

      .status-filters {
        display: flex;
        align-items: center;
        gap: 8px;
        .input {
          align-self: stretch;
          font-family: Pretendard;
          padding: 6px 12px;
          align-items: center;
          gap: var(--15, 6px);
          border-radius: 4px;
          border: 1px solid #d2d2d9;
          color: #303033;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 21px */
        }
        span {
          color: #212121;
          font-size: 14px;
          font-weight: 800;
          line-height: 150%; /* 21px */
          white-space: nowrap;
        }
        .dateSearchWrapper {
          .inputBaseWrapper {
            display: flex;
            padding: 6px 12px;
            align-items: center;
            gap: var(--15, 6px);
            border-radius: 4px;
            border: 1px solid #d2d2d9;
            & * input {
              width: 80px;
              text-align: center;
              color: #303033;
              font-family: Pretendard;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 150%; /* 21px */
              border: none;
              padding: 0;
            }
          }
        }
        .filterSelectWrapper {
          display: flex;
          align-items: center;
          gap: 8px;
          & > span {
            cursor: pointer;
            transition: all 0.3s;
            display: flex;
            padding: 8px var(--3, 12px);
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: var(--25, 10px);
            border-radius: 36px;
            border: 1px solid #ececec;
            background: #f8f8f8;
            color: #969696;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            &.active,
            &:hover {
              border-radius: 320px;
              border: 1px solid #d5ffbf;
              background: #f4ffee;
              color: #017534;
              font-weight: 700;
            }
          }
        }
      }
    }
  }

  .table-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 8px;
    border: 1px solid #e3e3e3;
    width: 100%;
    box-sizing: border-box;
    overflow-x: scroll;
    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #d2d2d9;
      border-radius: 4px;
    }
    &::-webkit-scrollbar-track {
      background-color: #f8f8f8;
    }

    table {
      width: 100%;
      font-family: Pretendard;

      tr {
        border-radius: 4px;
        border-bottom: 1px solid #e5e5e5;
        background-color: #fff;
        &:nth-child(2n) {
          background: #fcfcfc;
        }
      }
      th,
      td {
        padding: var(--3, 12px) var(--6, 24px);
        color: #313131;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 24.87px */
        vertical-align: middle;
        white-space: nowrap;
        &.center {
          text-align: center;
        }
        &.right {
          text-align: right;
        }
        &.left {
          text-align: left;
        }
        .sortWrapper {
          display: flex;
          justify-content: space-around;
          align-items: center;
          span {
            width: var(--35, 14px);
            height: var(--35, 14px);
            flex-shrink: 0;
          }
        }
        button {
          margin: 0 auto;
        }
      }
      th {
        color: #878787;
        font-weight: 400;
        div {
          color: #878787;
        }
      }
    }

    .pagination {
      button {
      }

      span {
      }
    }
  }
  @include mobile() {
    padding: var(--8, 16px);
    .filter-section {
      padding: 12px;
      .filters {
        flex-wrap: wrap;
        gap: 8px;
        & > button {
          width: 100%;
        }
        .status-filters {
          width: 100%;
          .dateSearchWrapper {
            .inputBaseWrapper {
              flex: 1;
              & * input {
                width: 100%;
                font-size: 12px;
              }
            }
          }
          .input {
            font-size: 12px;
          }
          span {
            font-size: 12px;
          }
          .dateSearchWrapper {
            .inputBaseWrapper {
              & * input {
                font-size: 12px;
              }
            }
          }
          .filterSelectWrapper {
            flex-wrap: wrap;
            & > span {
              font-size: 12px;
            }
            select {
              flex: 1;
            }
            input {
              width: 100% !important;
            }
            button {
              width: 100%;
            }
          }
        }
      }
    }
    .table-section {
      width: 100%;
      box-sizing: border-box;
      overflow-x: scroll;
      table {
        th,
        td {
          white-space: nowrap;
          padding: var(--3, 8px) var(--6, 12px);
          font-size: 12px;
        }
      }
    }
  }
}
