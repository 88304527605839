@import "./base_icon";
@import "_mixin_animation.scss";
hr {
  width: 100%;
  height: 0px;
  border: none;
  border-bottom: 1px solid #eeeeee;
  margin: -6px 0;
}
* button {
  cursor: pointer;
}
@mixin background {
  @content;
  background-position: center;
  background-repeat: no-repeat;
}
.button {
  display: flex;
  padding: var(--3, 12px) var(--5, 20px);
  justify-content: center;
  align-items: center;
  gap: var(--2, 8px);
  border-radius: 32px;
  background: #017534;
  border: none;
  color: var(--white, var(--white, #fff));
  font-family: Pretendard;
  font-size: 16px;
  font-weight: 700;
  &.strock {
    background: #fff;
    border: 1px solid #017534;
    color: #017534;
  }
  &.fullWidth {
    width: 100%;
  }
  &.red {
    background: #fff;
    border: 1px solid #e70000;
    color: #e70000;
  }
  @include mobile() {
    padding: 12px 16px;
    font-size: 14px;
  }
}
.bedge-wrapper {
  display: flex;
  gap: 12px;
}
.bedge {
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  line-height: 150%; /* 24px */
  border-radius: 4px;
  padding: 2px 12px;
  padding-bottom: 4px;
  white-space: nowrap;
  display: inline-block;
  font-family: Pretendard;
  &.small {
    font-size: 12px !important;
  }
  &.width-50 {
    width: 50%;
  }
  &.middle {
    font-size: 14px !important;
  }
  &.blue {
    background: #f7fbff !important;
    color: #0024df !important;
    border: 1px solid #f3f9ff !important;
  }
  &.sky-blue {
    background: #dcf1ff !important;
    color: #003cc9 !important;
  }
  &.green {
    background: #effff1 !important;
    color: #017534 !important;
  }
  &.yellow-green {
    color: #86a800 !important;
    background: #f7ffd8 !important;
  }
  &.yellow {
    color: #8b7800 !important;
    background: #fffccb !important;
  }
  &.orange {
    background: #fff4e6 !important;
    color: #cf7500 !important;
  }
  &.red {
    background: #ffe6e6 !important;
    color: #ff0000 !important;
  }
  &.white {
    border-radius: 2px;
    border: 1px solid #d8d8d8 !important;
    background: #fff !important;
  }
  @include mobile {
    font-size: 14px;

    &.middle {
      font-size: 12px;
    }
    &.small {
      font-size: 12px;
    }
    &.width-50 {
      width: fit-content;
    }
  }
}
.popup-wrapper {
  width: fit-content;
  border-radius: 24px;
  border: 1px solid #dfdfdf;
  background: #fff;
  .popup-title {
    display: flex;
    padding: 12px 18px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-bottom: 1px solid #dfdfdf;
    h1 {
      color: #000;
      text-align: center;
      font-family: Pretendard;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 27px */
    }
    .closeIcon {
      width: 14px;
      height: 14px;
    }
  }
  .popup-content {
    display: flex;
    padding: 24px 18px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    min-width: 33svw;
    font-family: Pretendard;
    .overflowSection {
      max-height: 70svh;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      width: 100%;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        width: 8px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #d4d4d4;
        border-radius: 4px;
        width: 8px;
      }
      &::-webkit-scrollbar-track {
        background-color: #f9f9f9;
      }
    }
    &.noGap {
      gap: 0;
    }
    hr {
      width: 100%;
      height: 0px;
      border: none;
      border-bottom: 1px solid #dfe7f1;
    }
    .notice-section {
      display: flex;
      padding: var(--4, 16px) 18px;
      flex-direction: column;
      align-items: flex-start;
      gap: var(--3, 12px);
      align-self: stretch;
      border-radius: 4px;
      border: 1px solid #f6f6ff;
      background: #fcfcff;
      width: 100%;
      box-sizing: border-box;
      &.white {
        background-color: #fff;
        border-bottom: none;
        border-radius: 4px 4px 0 0;
      }
      h3 {
        color: #2c2c2c;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 125%; /* 17.5px */
      }
      h2 {
        display: flex;
        align-items: center;
        gap: 4px;
        color: #373a44;
        font-family: Pretendard;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 110%; /* 17.6px */
      }
      p {
        color: #3f424f;
        font-family: Pretendard;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 21px */
      }
      ul {
        li {
          color: #3f424f;
          font-family: Pretendard;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%; /* 21px */
          list-style-type: disc;
          margin-left: 14px;
        }
      }
    }
    .contactAdminContent {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
    }
    .form-group {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: var(--3, 12px);
      align-self: stretch;
      font-family: Pretendard;
      & > span,
      & > p {
        color: #303033;
        font-size: 16px;
        font-weight: 400;
        line-height: 150%; /* 21px */
      }

      &.row {
        flex-direction: row;
        button {
          width: fit-content;
          white-space: nowrap;
          padding: 8px 18px;
        }
      }
      label {
        color: #353535;
        font-size: 18px;
        font-weight: 600;
      }

      input,
      select,
      textarea {
        font-family: Pretendard;

        width: 100%;
        padding: 8px 12px;
        align-items: center;
        gap: var(--15, 6px);
        box-sizing: border-box;
        border-radius: 4px;
        border: 1px solid #d2d2d9;
        color: #303033;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 21px */
      }

      .button {
        padding: 10px 8px;
        width: 100%;
      }
    }
    & > .button {
      align-self: center;
      padding: 10px 14px;
      min-width: 140px;
    }
    .docsCard {
      display: flex;
      padding: 12px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: var(--3, 12px);
      align-self: stretch;
      border-radius: 8px;
      border: 1px solid #d4d4d4;
      min-width: 43svw;
      max-width: 43svw;
      box-sizing: border-box;
      .header {
        .cardTitleWrapper {
          display: flex;
          align-items: center;
          gap: var(--3, 12px);
          h3 {
            color: #23232a;
            font-family: Pretendard;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 150%; /* 24px */
          }
        }
      }
      h3 {
        color: #23232a;
        font-family: Pretendard;
        font-size: 16px;
        font-style: normal;
        width: 100%;
        text-align: left;
        font-weight: 700;
        line-height: 150%; /* 24px */
      }
      .moreWrapper {
        width: 100%;
        max-height: 120px;
        overflow-y: scroll;
        display: flex;
        gap: 12px;
        flex-direction: column;

        &::-webkit-scrollbar {
          width: 8px;
        }
        &::-webkit-scrollbar-thumb {
          background-color: #d4d4d4;
          border-radius: 4px;
          width: 8px;
        }
        &::-webkit-scrollbar-track {
          background-color: #f9f9f9;
        }
      }
      .button-group {
        display: flex;
        align-items: center;
        gap: var(--3, 12px);
        align-self: stretch;
        button {
          flex: 1;
        }
      }
    }
  }
  .button-list {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
  }
  @include mobile() {
    width: 90%;
    border-radius: 12px;
    .popup-title {
      padding: 12px 16px;
      h1 {
        font-size: 16px;
      }
    }
    .popup-content {
      padding: 16px;
      gap: 16px;
      .overflowSection {
        max-height: 60svh;
        gap: 4px;
      }
      .notice-section {
        padding: 12px;
        h3 {
          font-size: 12px;
        }
        h2 {
          font-size: 12px;
        }
        p {
          font-size: 12px;
        }
        ul {
          li {
            font-size: 12px;
          }
        }
      }
      .contactAdminContent {
        padding: 0;
        width: 100%;
        box-sizing: border-box;
      }
      .form-group {
        gap: 8px;
        & > span,
        & > p {
          font-size: 14px;
        }
        label {
          font-size: 14px;
        }
        input,
        select,
        textarea {
          font-size: 12px;
        }
        .button {
          padding: 8px 12px;
        }
      }
      & > .button {
        padding: 8px 12px;
      }
      .docsCard {
        padding: 12px;
        gap: 8px;
        min-width: 100%;
        max-width: 100%;
        .header {
          .cardTitleWrapper {
            gap: 8px;
            h3 {
              font-size: 12px;
            }
          }
        }
        h3 {
          font-size: 14px;
        }
        .moreWrapper {
          gap: 8px;
          max-height: 100px;
        }
        .button-group {
          gap: 8px;
        }
      }
    }
  }
}
.popupWrapper {
  background-color: #00000083;
  width: 100svw;
  height: 100svh;
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  opacity: 0;
  z-index: 10;
  transition: all 0.3s;
  &.active {
    pointer-events: all;
    opacity: 1;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  @include mobile {
    position: fixed;
  }
}
